@import "../bootstrap/functions";
@import "../variables";

body.login {
  background: url('../../images/content/bg-destinos-header.jpg') no-repeat center center;
  background-size: cover;
  
  #login {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0;
    min-height: 500px;
  }
  #wp-submit {
      background-color: theme-color('primary');
      border: 1px solid darken(theme-color('primary'), 5%) !important;
      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      transition: 250ms ease all;

    &:hover {
      background-color: darken(theme-color('primary'), 10%) !important;
    }
  }

}
